@import "src/styles/variables";

.case-overview {
  .ant-btn.ant-btn-link {
    padding: 0;
    margin-bottom: 5px;
  }
  .case-overview__img-wrapper {
    img.case-overview__img {
      width: 100%;
      height: 150px;
      object-fit: cover;
      border-radius: 10px;
    }
  }
}

// .case-overview__label {
//   color: $secondary-black;
//   margin-bottom: 5px;
// }

// .case-overview__value {
//   font-weight: bold;
// }

.case-overview__label {
  font-weight: bold;
  margin-bottom: 8px;
}

.case-overview__value {
  margin-bottom: 16px; /* Space between rows */
}

@media (max-width: 768px) {
  .case-overview__label {
    font-size: 14px; /* Adjust label font size for smaller screens */
  }

  .case-overview__value {
    font-size: 16px; /* Adjust value font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .case-overview__label {
    font-size: 12px; /* Further adjustments for very small screens */
  }

  .case-overview__value {
    font-size: 14px;
  }
}

.right-align {
  text-align: right; /* Align text to the right */
}