@import "src/styles/variables";


.doctor-cases__status-wrapper {
  width: 350px;
  span, button {
    display: inline-block;
    vertical-align: middle;
  }

  .ant-btn.ant-btn-primary:not(.ant-btn-icon-only) {
    background-color: #1747E9;
    border: 1px solid #1747E9;
    padding: 4px 10px;
    font-size: 14px;
  }

  .ant-btn.ant-btn-primary {
    padding: 4px 15px;
  }
}

.doctor-cases__status {
  position: relative;
  text-transform: capitalize;
  font-weight: bold;
  color: $success-color;
  width: 100px;

  &.assigned {
    color: $warning-color;
  }

  &.unassigned {
    color: $danger-color;
  }
}

.clickable-icon {
  cursor: pointer;
  transition: transform 0.1s ease;
}

.clickable-icon:active {
  transform: scale(0.9);
}
