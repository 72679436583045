@import "src/styles/variables";

.scan-center-cases {

}

.scan-center-cases__status-loader {
  .ant-spin.ant-spin-lg.ant-spin-spinning.app-loader {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 1rem;
    .ant-spin-dot {
     font-size: 1.5rem;
    }
  }
}
.clickable-icon {
  cursor: pointer;
  transition: transform 0.1s ease;
}

.clickable-icon:active {
  transform: scale(0.9);
}