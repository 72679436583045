@import "src/styles/variables";
.auth-wrapper {
  .auth-wrapper__auth-section {
    background-color: #FFFFFF;
    color: $primary-black;
    display: inline-block;
    vertical-align: middle;
    width: 50%;
    height: 100vh;
    position: relative;
  }

  .auth-wrapper__logo-section {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 50%;
    height: 100vh;
    background-color: $primary-color;
    color: #FFFFFF;
    font-size: 30px;
  }

  .auth-wrapper__logo-section-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 150px;
    margin: auto;
  }

  .auth-wrapper__logo, .auth-wrapper__logo-subtext {
    //width: 200px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 0;
  }
  .auth-wrapper__logo-subtext {
    letter-spacing: 4px;
  }
  .auth-wrapper__powered-by-text {
    > span {
      &:first-child {
        font-size: 0.9rem;
      }
      &:last-child {
        margin-left: 5px;
      }
    }
    text-align: center;
    color: #FFFFFF;
    margin: 17rem 0 0;
    letter-spacing: 3px;
    font-size: 1.2rem;
    line-height: 2rem;
  }
}
