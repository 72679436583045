// @import "src/styles/variables";

// .viewer-wrapper {
//     background-color: #000;
//     .viewer-wrapper__iframe-wrapper {
//         position: fixed;
//         top: 0;
//         left: 0;
//         width: 100vw;
//         height: 100vh;
//         z-index: 2;
//         border: none;
//         background-color: #000;
//     }
//     iframe.viewer-wrapper__iframe {
//         position: fixed;
//         top: 0;
//         left: 0;
//         width: 100vw;
//         height: 100vh;
//         z-index: 2;
//         border: none;
//     }
//     .viewer-wrapper__generate-wrapper {
//         position: fixed;
//         z-index: 4;
//         right: 1rem;
//         top: 1rem;
//     }
// }

// .ant-drawer.ant-drawer-open.viewer-wrapper__generate-report-drawer {
//     width: 40%;
//     .ant-drawer-mask {
//         display: none;
//     }
// }
@import "src/styles/variables";

.viewer-wrapper {
    background-color: #000;

    // Default full-screen viewer when the drawer is closed
    .viewer-wrapper__iframe-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 2;
        border: none;
        background-color: #000;
    }

    iframe.viewer-wrapper__iframe {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 2;
        border: none;
    }

    .viewer-wrapper__generate-wrapper {
        position: fixed;
        z-index: 4;
        right: 1rem;
        top: 1rem;
    }

    // When the drawer is open, adjust the width
    &.drawer-open {
        .viewer-wrapper__iframe-wrapper {
            width: 60vw; // Reduce the width to 60% when drawer is open
            left: 0; // Keep the viewer aligned to the left
        }

        iframe.viewer-wrapper__iframe {
            width: 60vw; // Adjust the iframe width to match
            left: 0; // Keep the iframe aligned to the left
        }
    }
}

// Drawer styles
.ant-drawer.ant-drawer-open.viewer-wrapper__generate-report-drawer {
    width: 40%;
    right: 0; // Ensure the drawer opens from the right side
    .ant-drawer-mask {
        display: none;
    }
}
