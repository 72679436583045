@import "src/styles/variables";

.attachment-upload {

}

.attachment-upload__input {
  width: 100%;
  padding: 2rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  border: 1px dashed $secondary-black;
}

.attachment-upload__icon {
  display: block;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.attachment-upload__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.attachment-upload__title {
  margin: 0 0 6px 0;
  color: $secondary-black;
  font-weight: 500;
}

.attachment-upload__img-wrapper {
  position: relative;
  margin-top: 2rem;
  .attachment-upload__close-button {
    cursor: pointer;
    position: absolute;
    top: -11px;
    right: -5px;
    background-color: $secondary-black;
    border-radius: 50%;
    padding: 0.5rem;
    line-height: 0.75rem;
    svg {
      font-size: 0.75rem;
    }
  }
}
