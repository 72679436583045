@import "src/styles/variables";

.scan-center-list {

}

.scan-center-list_actions {
  .anticon {
    color: $primary-black;
    margin-right: 1rem;
    font-size: 1.3rem;
    cursor: pointer;
  }
}
