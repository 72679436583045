@import "src/styles/variables";

.app-card {
  padding: 2rem;
  box-shadow: $primary-shadow;
  border-radius: 4px;
  max-width: 100%;   /* Set the maximum width */
  overflow-x: auto;  /* Enables horizontal scrolling */
  // white-space: nowrap; /* Prevents content from wrapping */
}

/* Tablet devices (768px and below) */
@media (max-width: 768px) {
  .app-card {
    padding: 1.5rem;    /* Slightly reduce padding for smaller screens */
    max-width: 100%;
    width: auto;        /* Let it adapt to screen width */
    overflow-x: auto;   /* Horizontal scrolling if necessary */
  }
}

/* Mobile devices (480px and below) */
@media (max-width: 480px) {
  .app-card {
    padding: 1rem;      /* Further reduce padding for smaller mobile screens */
    max-width: 100%;    
    width: auto;
    overflow-x: auto;
  }
}
