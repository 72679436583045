@import "src/styles/variables";

.admin-cases__status-wrapper {

  width: 380px;
  span, button {
    display: inline-block;
    vertical-align: middle;
  }
}
.admin-cases__patient-name {
  max-width: 200px;
}

.admin-cases__status {
  position: relative;
  text-transform: capitalize;
  font-weight: bold;
  color: $success-color;
  width: 125px;

  &.assigned {
    color: $warning-color;
  }

  &.unassigned {
    color: $danger-color;
  }
}

.ant-popover.admin-cases__assignment-popover {
  width: 300px;
}

.clickable-icon {
  cursor: pointer;
  transition: transform 0.1s ease;
}

.clickable-icon:active {
  transform: scale(0.9);
}
