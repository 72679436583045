
/* Color palette */

$primary-color: #1747E9;
$primary-color-light: #1747E990;
$secondary-color: #006FBA;
$white: #FFFFFF;
$light-white: #FFFFFF40;
$primary-black: #2B2B2B;
$secondary-black: #BDBDBD;
$primary-border: #bebebe;
$input-bg: #f8fbfc;
$dark-blue: #22509D;
$light-grey: #00000015;
$dark-grey: #8898AA;
$success-color: green;
$danger-color: #FE0000;
$warning-color: #F3B21B;
$body-bg: #F5F8FA;

/* Shadows */
$primary-shadow: 0 8px 22px #0000001A;
$secondary-shadow: 0px 3px 6px #2C28281C;

/* Spacer variables */
$spacer-x: 1rem;
$spacer-y: 1rem;
