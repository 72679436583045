@import "src/styles/variables";

.report-form {
  .ck.ck-editor {
    width: 100%;
  }
  form {
    margin-top: 1rem;
  }
}

.report-form__submit-wrapper {
  width: 100%;
  margin-top: 2rem;
}

.report-form__label {
  font-weight: bold;
  font-size: 18px;
}

.report-form__value {
  margin-top: 10px;
}
