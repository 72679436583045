@import "src/styles/variables";

.case-assignment-form {

  .ant-btn.ant-btn-primary {
    padding: 4px 16px;
  }

  .dropdown-field {
    margin-bottom: 0;
  }
}
.case-assignment-form__toggle-wrapper {
  span, button {
    margin-right: 10px;
  }
  span {
    font-weight: bold;
  }
}

.case-assignment-form__submit-wrapper {
  margin-top: 1rem;
  text-align: right;
  > button {
    &:first-child {
      margin-right: 0.5rem;
    }
  }
}
