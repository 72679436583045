@import "src/styles/variables";

.case-filter-form {
  position: relative;
}

/* Tablet devices (768px and below) */
@media (max-width: 768px) {
  .case-filter-form__show-filter {
    top: -36px;  /* Adjust vertical positioning */
    left: 30px;   /* Center it more on smaller screens */
    font-size: 24px;  /* Slightly smaller font size */
  }
}

/* Mobile devices (480px and below) */
@media (max-width: 480px) {
  .case-filter-form__show-filter {
    top: -24px;  /* Further adjust vertical positioning */
    left: 20px;   /* Further adjust left positioning */
    font-size: 20px;  /* Further reduce font size */
  }
}
