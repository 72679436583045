@import "../../../styles/variables";

.progress-bar__wrapper {
    display: flex;
    align-items: center;
    margin: 1rem 0;
    .progress-bar__percent {
        margin-left: 0.5rem;
    }
}

.progress-bar {
  width: 100%;
  height: 20px;
  position: relative;
  background-color: $secondary-black;
  border-radius: 20px;
  .progess-bar__fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 60%;
    height: 20px;
    border-radius: 50px;
    background-color: $primary-color;
  }
}