@import "src/styles/variables";

.report-template-list {

}

.report-template-list__search-wrapper {
    margin: 1rem 0;
    .ant-input-group {
        input.ant-input {
            padding: 10px 15px;
            height: auto;
            z-index: 0;
        }
    }

    button {
        height: auto;
        width: auto;
        padding: 9px 15px !important;
        color: $white !important;
    }
}
