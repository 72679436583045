@import "src/styles/variables";

.doctor-form {

}

.doctor-form__switch {
    margin-bottom: 1.5rem;
    span {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }
    .doctor-form__switch-title {
        margin: 0 0 10px;
        color: $secondary-black;
        font-weight: bold;
      }
}
