@import "src/styles/variables";

.doctor-profile {
  form {
    width: 70%;
    margin-top: 2rem;
  }
}

.doctor-profile__switch {
  margin-bottom: 1.5rem;
  span {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
}

.doctor-profile__signature {
  width: 150px;
}